<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title> Keterkaitan Entitas {{ kota }} </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- LIST ENTITY -->
      <div class="scroller">
        <div
          v-for="entity in entity"
          :key="entity.id"
          class="klik clickable mb-1"
          :class="entity.id == item_selected ? 'active' : ''"
          @click="getItem(entity.id)"
        >
          <div class="d-flex align-items-center pxy">
            <b-media-aside>
              <!-- USER ICON -->
              <b-avatar
                v-if="entity.type == 'institution'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon icon="UsersIcon" size="27" class="text-warning" />
              </b-avatar>

              <!-- MAPS ICON -->
              <b-avatar
                v-if="entity.type == 'place'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon
                  icon="MapPinIcon"
                  size="27"
                  class="text-success"
                />
              </b-avatar>

              <!-- BOOK ICON -->
              <b-avatar
                v-if="entity.type == 'news'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon icon="BookIcon" size="27" class="text-danger" />
              </b-avatar>

              <!-- CALENDAR ICON -->
              <b-avatar
                v-if="entity.type == 'time'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="27"
                  style="color: #aae826"
                />
              </b-avatar>

              <!-- PERSON ICON -->
              <b-avatar
                v-if="entity.type == 'person'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon icon="UserIcon" size="27" class="text-info" />
              </b-avatar>

              <!-- BUILDING ICON -->
              <b-avatar
                v-if="entity.type == 'build'"
                rounded
                size="50"
                variant="light-primary"
              >
                <i
                  class="fa-solid fa-building-columns fa-2x"
                  style="color: #e144a5"
                ></i>
              </b-avatar>

              <!-- CALENDAR EVENT ICON -->
              <b-avatar
                v-if="entity.type == 'event'"
                rounded
                size="50"
                variant="light-primary"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="27"
                  class="text-primary"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <!-- ENTITY NAME -->
              <div class="mr-auto">
                <h4>{{ entity.name }}</h4>
              </div>

              <!-- ENTITY MENTION VALUE -->
              <div class="mr-auto">{{ entity.mention }} Mention(s)</div>
            </b-media-body>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
  <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "cardKeterkaitanEntitas",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    vSelect,
  },

  data: () => ({
    kota: "Indonesia",
    item_selected: null,
    entity: [
      {
        id: 1,
        name: "Indonesia",
        mention: 272,
        type: "place",
      },
      {
        id: 2,
        name: "Selasa (6/9/22)",
        mention: 272,
        type: "time",
      },
      {
        id: 3,
        name: "Kemenkes",
        mention: 272,
        type: "institution",
      },
      {
        id: 4,
        name: "BPJS",
        mention: 272,
        type: "news",
      },
      {
        id: 5,
        name: "Universitas Indonesia",
        mention: 272,
        type: "build",
      },
      {
        id: 6,
        name: "Sambo",
        mention: 272,
        type: "person",
      },
      {
        id: 7,
        name: "Panwascam Pemilu 2024",
        mention: 272,
        type: "event",
      },
      {
        id: 8,
        name: "BPJS",
        mention: 272,
        type: "news",
      },
      {
        id: 9,
        name: "BPJS",
        mention: 272,
        type: "news",
      },
      {
        id: 10,
        name: "BPJS",
        mention: 272,
        type: "news",
      },
    ],
  }),

  methods: {
    getItem(id) {
      this.item_selected = id;
    },
  },
};
</script>
<style scoped>
.klik :hover {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.active {
  background-color: #d3eeff;
  border-radius: 10px;
}
.dark-layout * .active {
  background-color: #161d31;
  border-radius: 10px;
}
.dark-layout * .klik :hover {
  background-color: #202940;
  border-radius: 10px;
}
.pxy {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.scroller {
  display: block;
  height: 25rem;
  overflow: scroll;
}
.scroller::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.scroller::-webkit-scrollbar-corner {
  background-color: #d3eeff;
  border-radius: 10px;
}
.dark-layout * .scroller::-webkit-scrollbar-thumb {
  background-color: #343b51 !important;
  border-radius: 10px;
}
.dark-layout * .scroller::-webkit-scrollbar-track {
  background-color: #202940 !important;
  border-radius: 10px;
}
.dark-layout * .scroller::-webkit-scrollbar-corner {
  background-color: #343b51 !important;
  border-radius: 10px;
}
</style>