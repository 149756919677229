<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Grafik Topik Terkait {{ kota }} </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- BAR CHART COMPONENT -->
      <dataBarChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import dataBarChart from "@/components/usable/dataBarChart.vue";
import { $themeColors } from "@themeConfig";

export default {
  name: "cardGrafikTopikTerkait",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    dataBarChart,
  },

  data: () => ({
    kota: "Indonesia",
    chart_data: {
      colors: [$themeColors.success, $themeColors.primary, $themeColors.danger],
      categories: [
        "7/12",
        "8/12",
        "9/12",
        "10/12",
        "11/12",
        "12/12",
        "13/12",
        "14/12",
        "15/12",
        "16/12",
      ],
      series: [
        {
          name: "Positif",
          data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
        },
        {
          name: "Netral",
          data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
        },
        {
          name: "Negatif",
          data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
        },
      ],
    },
  }),
};
</script>