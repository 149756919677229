<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Berita Terkait {{ kota }} </b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="scroll-component px-2 pt-1">
        <div v-for="items in item_pages" :key="items.id" class="shadow rounded">
          <!-- NEWS CARD COMPONENT -->
          <newsMedsosCard />
        </div>
        <!-- PAGINATION -->
        <div class="row">
          <div class="col-md-12 float-right">
            <b-pagination
              v-model="current_page"
              :total-rows="items.length"
              :per-page="per_page"
              align="right"
              size="sm"
              @change="updatePage"
            />
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
  <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BPagination,
} from "bootstrap-vue";
import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";

export default {
  name: "cardBeritaTerkait",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BPagination,
    newsMedsosCard,
  },

  data: () => ({
    kota: "Indonesia",
    current_page: 1,
    per_page: 5,
    item_pages: [],
    items: [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
      {
        id: 5,
      },
      {
        id: 6,
      },
      {
        id: 7,
      },
      {
        id: 8,
      },
      {
        id: 9,
      },
      {
        id: 10,
      },
    ],
  }),

  created() {
    this.updateItemPages();
  },

  methods: {
    updatePage(page_number) {
      this.current_page = page_number;
      this.updateItemPages();
    },

    updateItemPages() {
      this.item_pages = this.items.slice(
        (this.current_page - 1) * this.per_page,
        (this.current_page - 1) * this.per_page + this.per_page
      );
      if (this.item_pages.length == 0 && this.current_pages == 0) {
        this.updatePage(this.current_pages - 1);
      }
    },
  },
};
</script>
<style>
.dark-layout * .shadow {
  box-shadow: 0.5px 0.5px 0.5px 1px #d0d2d6 !important;
}
.scroll-component {
  display: block;
  height: auto;
  max-height: 30rem;
  overflow: scroll;
}
.scroll-component::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.scroll-component::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}
.scroll-component::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.scroll-component::-webkit-scrollbar-corner {
  background-color: #d3eeff;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-thumb {
  background-color: #343b51 !important;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-track {
  background-color: #202940 !important;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-corner {
  background-color: #343b51 !important;
  border-radius: 10px;
}
</style>