<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Grafik Emosi {{ kota }} </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- PIE CHART COMPONENT -->
      <pieChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import pieChart from "@/components/usable/pieChart.vue";
import { $themeColors } from "@themeConfig";

export default {
  name: "cardGrafikEmosi",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    pieChart,
  },

  data: () => ({
    kota: "Indonesia",
    chart_data: {
      colors: [$themeColors.success, $themeColors.primary, $themeColors.danger],
      legend: true,
      labels: ["Positif", "Netral", "Negatif"],
      series: [5, 6, 7],
    },
  }),
};
</script>