<template>
    <div class="mb-2">
      <div class="row">
        <!-- ENTITY NAVIGATION -->
        <div class="col-md-3">
          <cardEntitas />
        </div>
  
        <!-- CONTENT DATA ENTITY -->
        <div class="col-md-9">
          <!-- ROW 1 -->
          <div class="row" style="margin-bottom:2rem">
            <!-- BERITA TERKAIT -->
            <div class="col-md-8">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Berita Terkait'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Berita Terkait ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardBeritaTerkait />
            </div>
  
            <!-- GRAFIK SENTIMEN -->
            <div class="col-md-4">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Grafik Sentimen'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Grafik Sentimen ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardGrafikSentimen />
            </div>
          </div>
  
          <!-- ROW 2 -->
          <div class="row" style="margin-bottom:2rem">
            <!-- GRAFIK EMOSI -->
            <div class="col-md-4">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Grafik Emosi'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Grafik Emosi ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardGrafikEmosi />
            </div>
  
            <!-- GRAFIK TREN MENTION -->
            <div class="col-md-8">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Grafik Tren Mention'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Grafik Tren Mention ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardGrafikTrenMention />
            </div>
          </div>
  
          <!-- ROW 3 -->
          <div class="row mt-2">
            <!-- GRAFIK TOPIK TERKAIT -->
            <div class="col-md-8">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Grafik Topik Terkait'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Grafik Topik Terkait ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardGrafikTopikTerkait />
            </div>
  
            <!-- KETERKAITAN ENTITY -->
            <div class="col-md-4">
              <!-- ENTITY NOT SELECTED -->
              <!-- <alertWarning
                :title="'Keterkaitan Entitas'"
                :subtitle="'*Silahkan Pilih Entitas Terlebih Dahulu'"
              /> -->
  
              <!-- DATA IS EMPTY -->
              <!-- <alertWarning
                :title="'Keterkaitan Entitas ' + selected_entity"
                :subtitle="'Data Tidak Tersedia !'"
              /> -->
  
              <!-- DATA AVAILABLE -->
              <cardKeterkaitanEntitas />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import alertWarning from "@/components/usable/alertWarning.vue";
  import cardEntitas from "@/components/analytic/ner/cardEntitas.vue";
  import cardBeritaTerkait from "@/components/analytic/ner/cardBeritaTerkait.vue";
  import cardGrafikSentimen from "@/components/analytic/ner/cardGrafikSentimen.vue";
  import cardGrafikEmosi from "@/components/analytic/ner/cardGrafikEmosi.vue";
  import cardGrafikTrenMention from "@/components/analytic/ner/cardGrafikTrenMention.vue";
  import cardGrafikTopikTerkait from "@/components/analytic/ner/cardGrafikTopikTerkait.vue";
  import cardKeterkaitanEntitas from "@/components/analytic/ner/cardKeterkaitanEntitas.vue";
  
  export default {
    name: "NER",
    components: {
      alertWarning,
      cardEntitas,
      cardBeritaTerkait,
      cardGrafikSentimen,
      cardGrafikEmosi,
      cardGrafikTrenMention,
      cardGrafikTopikTerkait,
      cardKeterkaitanEntitas,
    },
  
    data: () => ({
      title: "Named Entity Recognizer | Online Media Monitoring",
      selected_entity: "Indonesia",
    }),
  
    created() {
      document.title = this.title;
    },
  };
  </script>